import date from 'date-and-time'

const getFileDate = (filename) => {
  const pattern = /\d{2}/g
  const [yy, mm, dd] = filename.match(pattern)
  const d = date.parse(`${mm}-${dd}-19${yy}`, 'MM-DD-YYYY')
  if (isNaN(d)) {
    return `19${yy}`
  }
  return d
}

export const fileSorter = (a, b) => {
  const dateA = getFileDate(a)
  const dateB = getFileDate(b)
  if (typeof dateA === 'string' || typeof dateB === 'string') {
    return 0
  }
  return getFileDate(a) > getFileDate(b) ? 1 : -1
}

export const getDateString = (filename) => {
  const d = getFileDate(filename)
  if (typeof d === 'string') {
    return d
  }
  return date.format(d, 'MMMM D, YYYY', true)
}

export const getDateNumeric = (filename) => {
  const d = getFileDate(filename)
  if (typeof d === 'string') {
    return d
  }
  return date.format(d, 'YYYYMMDD', true)
}
