import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

function Transcript() {
  const { date } = useParams()

  const [transcript, setTranscript] = useState(null)

  useEffect(() => {
    const getFiles = async () => {
      const res = await axios.get(`/api/transcript/${date}`)
      const { data } = res
      setTranscript(data.transcript)
    }

    getFiles()
  }, [date])

  if (transcript === null) {
    return 'Getting transcript...'
  }

  return (
    <div className="container transcript">
      <ReactMarkdown source={transcript} />
    </div>
  )
}

export default Transcript
