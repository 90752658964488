import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { getDateString, fileSorter, getDateNumeric } from './utils'
import transcripts from './transcripts.json'

function LetterTile({ baseUrl, filename, year }) {
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const getDocType = (filename) => {
    const types = ['postcard', 'letter']
    for (let t of types) {
      if (filename.includes(t)) {
        return t
      }
    }
  }

  return (
    <li className="collection-item amber lighten-3">
      <a href={`${baseUrl}/${year}/${filename}`}>
        {capitalize(getDocType(filename))} -- {getDateString(filename)}
      </a>
      {transcripts.includes(`${getDateNumeric(filename)}.md`) ? (
        <>
          {'  '}
          <a href={`/transcript/${getDateNumeric(filename)}`}>(Transcript)</a>
        </>
      ) : (
        ''
      )}
    </li>
  )
}

function LetterList() {
  const { year } = useParams()

  const [files, setFiles] = useState(null)
  // const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getFiles = async () => {
      const res = await axios.get('/api/filelist')
      const { data } = res
      // setLoading(false)
      setFiles(data)
    }

    getFiles()
  }, [])

  if (files === null) {
    return 'Getting file list...'
  }

  const currentLetters = files[year].sort(fileSorter)

  return (
    <div className="App container">
      <ul className="collection">
        {currentLetters.map((f, i) => {
          return <LetterTile key={`pdf-${i}`} baseUrl={files.baseUrl} filename={f} year={year} />
        })}
      </ul>
    </div>
  )
}

export default LetterList
