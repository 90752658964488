import React, { useState, useEffect } from 'react'
import './App.css'
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import LetterList from './LetterList'
import Transcript from './Transcript'

function App() {
  const [loading, setLoading] = useState(true)
  const [signedIn, setSignedIn] = useState(false)
  const [password, setPassword] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post('/api/signin', {
        password,
      })
      setPassword('')
      setSignedIn(true)
    } catch {
      console.log('bad password')
      setPassword('')
    }
  }

  useEffect(() => {
    const checkSignIn = async () => {
      const res = await axios.get('/api/status')
      const { data } = res
      setLoading(false)
      setSignedIn(data.signedIn)
    }

    checkSignIn()
  }, [])

  if (loading) {
    return 'Loading...'
  }

  if (!signedIn) {
    return (
      <div className="container">
        <p>Please enter password:</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            autoFocus
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <input className="btn" type="submit" value="Send" />
        </form>
      </div>
    )
  }
  return (
    <Router>
      <div className="amber lighten-4 main">
        <nav className="blue-grey darken-1">
          <ul>
            {[1943, 1944, 1945].map((y) => {
              return (
                <li key={`year-${y}`}>
                  <Link to={`/${y}`}>{y}</Link>
                </li>
              )
            })}
          </ul>
        </nav>

        <Switch>
          <Route path="/transcript/:date">
            <Transcript />
          </Route>
          <Route path="/:year">
            <LetterList />
          </Route>
          <Route path="/">
            <p>Select a year (above)</p>
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
